<template>
  <div class="box-card flex column">
    <div class="card-title">
      <!-- 返回首页 -->
      <div v-if="isLeftBtn" class='left-title-btn has-register' @click='handleLeftBtn'>
        <span class="el-icon-arrow-left"></span>
        <span>返回登录页</span>
      </div>
      <span
        class="title"
        v-if="title"
      >{{title}}</span>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: '6rem'
    },
    height: {
      type: String,
      default: '4.28rem'
    },
    title: {
      type: String,
      default: ''
    },
    isLeftBtn:{
      type:Boolean,
      default:false
    }
  },
  data () {
    return {

    }
  },
  methods:{
    handleLeftBtn(){
      this.$router.push({name:'Login'})
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/login/_form.scss';
@import '@/scss/login/_box_card.scss';
</style>