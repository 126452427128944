<template>
  <section class="login-page flex justifyCenter alignCenter com-bg-img">
    <LoginCard title="登录">
      <div class="login-form-box">
        <el-form
          :model="loginForm"
          :rules="rules"
          ref="loginForm"
          :disabled="isLoading"
          label-width="100px"
          class="loginForm"
          label-position="top"
        >
          <el-form-item label="账号" prop="username">
            <el-input
              v-model="loginForm.username"
              placeholder="请输入账号"
              maxlength="11"
            ></el-input>
          </el-form-item>
          <el-form-item class="password" label="密码" prop="password">
            <el-input
              v-model.trim="loginForm.password"
              placeholder="请输入密码"
              :type="eyeFlag ? 'text' : 'password'"
              maxlength="18"
            >
              <img
                slot="suffix"
                :src="eyeFlag ? eyeImg : eyeOffImg"
                class="eye-img"
                @click="eyeFlag = !eyeFlag"
              />
            </el-input>
            <span
              class="forget-password"
              @click="
                $router.push({
                  name: 'ResetPassword',
                  query: { pass: 'forget' },
                })
              "
              >忘记密码</span
            >
          </el-form-item>
        </el-form>
        <div
          class="operation-user verify-confirm"
          @click="loginConfirm('loginForm')"
        >
          <Loading :loading="isLoading" color="#fff">
            <span>登录</span>
          </Loading>
        </div>
        <div class="has-register" @click="$router.push({ name: 'Register' })">
          <span>注册</span>
        </div>
      </div>
    </LoginCard>
    <!-- 滑块验证 -->
    <!-- <SlideVerify :verifyVisible='verifyVisible'></SlideVerify> -->
    <SlideVerify
      :show="isShow"
      :puzzleScale="0.8"
      :sliderSize="40"
      closeOnClickModal
      @success="onSuccess"
      @close="onClose"
    >
      <template v-slot:auth-header>
        <div class="auth-header">
          <span>请完成安全验证</span>
          <span class="el-icon-close" @click="isShow = false"></span>
        </div>
      </template>
    </SlideVerify>
  </section>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import LoginCard from "@/components/login/LoginCard.vue";
import SlideVerify from "@/components/login/slideVerify.vue";
import Loading from "@/components/common/Loading.vue";
import { SHA256 } from "@/utils/sha256";

import { userLogin } from "@/api/interface/login";

export default {
  components: {
    LoginCard,
    SlideVerify,
    Loading,
  },
  created() {},
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        let reg =
          /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)]|[\(\)])+$)([^(0-9a-zA-Z)]|[\(\)]|[a-z]|[A-Z]|[0-9]){6,18}$/;
        if (!reg.test(value)) {
          callback(
            new Error(
              "密码必须是6到18位，包含数字、特殊字符、大写和小写字符(任意两种)"
            )
          );
        }
        callback();
      }
    };
    return {
      isShow: false,
      isLoading: false,
      userInfo: {},
      eyeImg: require("@/assets/images/common/eye.png"),
      eyeOffImg: require("@/assets/images/common/eye-off.png"),
      eyeFlag: false,
      loginForm: {
        username: "",
        rememberMe: true,
        password: "",
      },
      rules: {
        username: {
          required: true,
          message: "请输入账号",
          trigger: ["change", "blur"],
        },
        password: {
          required: true,
          validator: validatePass,
          trigger: ["change", "blur"],
        },
      },
    };
  },
  mounted() {
    // 监听回车按键
    document.onkeydown = (e) => {
      let key = e.key;
      if (key === "Enter") {
        this.loginConfirm("loginForm");
      }
    };
  },
  computed: {
    ...mapGetters(["deviceInfo"]),
  },
  methods: {
    ...mapActions(["setUserInfo"]),
    // 登录
    handleLogin() {
      this.isLoading = true;
      userLogin({
        username: this.loginForm.username,
        rememberMe: true,
        password: SHA256(this.loginForm.password),
      })
        .then((data) => {
          this.setUserInfo(data);
          this.$message.success("登录成功！");
          this.$router.push({ name: "Index" });
        })
        .catch((e) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    loginConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.isShow = true;
        } else {
          return false;
        }
      });
    },
    // 滑块验证
    onSuccess() {
      this.handleLogin();
      this.isShow = false; // 通过验证后，需要手动关闭模态框
    },

    onClose() {
      this.isShow = false;
    },
  },
  beforeDestroy() {
    // 清空document键盘事件
    document.onkeydown = "";
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/login/_box_card.scss";
:deep() .el-form {
  .el-input {
    width: 100%;
  }
  .password {
    position: relative;
  }
  .forget-password {
    position: absolute;
    right: 0.21rem;
    top: 60px;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #a7a7a7;
    cursor: pointer;
  }
}
.login-page {
  width: 100%;
  height: 100vh;
}
.login-form-box {
  margin-top: 27px;
}
.auth-header {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -10px;
  span:first-child {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.02em;
    color: #000719;
  }
  span:last-child {
    font-size: 20px;
    color: #7b8293;
    cursor: pointer;
  }
}
</style>
