var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['vue-puzzle-vcode', { show_: _vm.show }],on:{"mousedown":_vm.onCloseMouseDown,"mouseup":_vm.onCloseMouseUp,"touchstart":_vm.onCloseMouseDown,"touchend":_vm.onCloseMouseUp}},[_c('div',{staticClass:"vue-auth-box_",on:{"mousedown":function($event){$event.stopPropagation();},"touchstart":function($event){$event.stopPropagation();}}},[_vm._t("auth-header"),_c('div',{staticClass:"auth-body_",style:(`height: ${_vm.canvasHeight}px`)},[_c('canvas',{ref:"canvas1",style:(`width:${_vm.canvasWidth}px;height:${_vm.canvasHeight}px`),attrs:{"width":_vm.canvasWidth,"height":_vm.canvasHeight}}),_c('canvas',{ref:"canvas3",class:['auth-canvas3_', { show: _vm.isSuccess }],style:(`width:${_vm.canvasWidth}px;height:${_vm.canvasHeight}px`),attrs:{"width":_vm.canvasWidth,"height":_vm.canvasHeight}}),_c('canvas',{ref:"canvas2",staticClass:"auth-canvas2_",style:(`width:${_vm.puzzleBaseSize}px;height:${_vm.canvasHeight}px;transform:translateX(${_vm.styleWidth -
            _vm.sliderBaseSize -
            (_vm.puzzleBaseSize - _vm.sliderBaseSize) *
              ((_vm.styleWidth - _vm.sliderBaseSize) /
                (_vm.canvasWidth - _vm.sliderBaseSize))}px)`),attrs:{"width":_vm.puzzleBaseSize,"height":_vm.canvasHeight}}),_c('div',{class:['loading-box_', { hide_: !_vm.loading }]},[_vm._m(0)]),_c('div',{class:['info-box_', { show: _vm.infoBoxShow }, { fail: _vm.infoBoxFail }]},[_vm._v(" "+_vm._s(_vm.infoText)+" ")]),_c('div',{class:['flash_', { show: _vm.isSuccess }],style:(`transform: translateX(${
            _vm.isSuccess
              ? `${_vm.canvasWidth + _vm.canvasHeight * 0.578}px`
              : `-${_vm.canvasHeight * 0.578}px`
          }) skew(-30deg, 0);`)}),_c('img',{staticClass:"reset_",attrs:{"src":_vm.resetSvg},on:{"click":_vm.reset}})]),_c('div',{staticClass:"auth-control_"},[_c('div',{staticClass:"range-box",style:(`height:${_vm.sliderBaseSize}px`)},[_c('div',{staticClass:"range-text"},[_vm._v(_vm._s(_vm.sliderText))]),_c('div',{ref:"range-slider",staticClass:"range-slider",style:(`width:${_vm.styleWidth}px`)},[_c('div',{class:['range-btn', { isDown: _vm.mouseDown }],style:(`width:${_vm.sliderBaseSize}px`),on:{"mousedown":function($event){return _vm.onRangeMouseDown($event)},"touchstart":function($event){return _vm.onRangeMouseDown($event)}}},[_c('div'),_c('div'),_c('div')])])])])],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"loading-gif_"},[_c('span'),_c('span'),_c('span'),_c('span'),_c('span')])
}]

export { render, staticRenderFns }