<template>
  <div class="loading-wrap">
    <template v-if='loading'>
        <!-- 默认按钮加载器 -->
      <div v-if="loadName==='dotLoading'" class="loading dotLoading" :style="`color:${color};`">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <!-- 骨架屏 -->
    </template>
    <template v-else>
      <slot></slot>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      required: true,
      type: Boolean,
      default: false
    },
    color:{
      type:String,
      default:',#000'
    },
    loadName:{
      type:String,
      default:'dotLoading'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_loading.scss';
.loading-wrap{
  width: 100%;
  height: 100%;
}
</style>